.landing {
  --background-color: #1f213e;
  background-color: var(--background-color);
}

.landing-wrapper {
  max-width: 1600px;
  min-height: 100vh;
  margin: 0 auto;
  padding: 1rem;
}

.landing-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: min(3vw, 2rem) min(3vw, 4rem);
}

.landing-nav__links {
  display: flex;
  gap: clamp(1rem, 3vw, 3rem);
  list-style-type: none;
}

.landing-nav__links img {
  vertical-align: middle;
  cursor: pointer;
}

.landing-nav__logo {
  width: 60px;
}

.landing-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.landing-content__full-moon {
  width: 200px;
  filter: drop-shadow(0px 4px 40px rgba(255, 255, 255, 0.7));
  animation: full-moon-rotating 15s infinite linear;
}

@keyframes full-moon-rotating {
  from {
    transform: rotate(0deg);
    filter: drop-shadow(0px 4px 40px rgba(255, 255, 255, 0.9));
  }

  50% {
    transform: rotate(180deg);
    filter: drop-shadow(0px 4px 40px rgba(255, 255, 255, 0));
  }

  to {
    transform: rotate(360deg);
    filter: drop-shadow(0px 4px 40px rgba(255, 255, 255, 0.9));
  }
}

.landing-content h1 {
  margin: 4rem 0 2rem 0;
  text-shadow: 0px 4px 40px rgba(255, 255, 255, 0.7);
  animation: hero-text-flicker 15s linear infinite;
}

@keyframes hero-text-flicker {
  from {
    text-shadow: 0px 4px 40px rgba(255, 255, 255, 0.85);
  }

  50% {
    text-shadow: 0px 4px 40px rgba(255, 255, 255, 0);
  }

  to {
    text-shadow: 0px 4px 40px rgba(255, 255, 255, 0.85);
  }
}

.landing-content p {
  display: block;
  max-width: 450px;
  font-size: 1.25rem;
}

.landing-content__coming-soon {
  font-family: monospace;
  position: relative;
  text-transform: uppercase;
  margin: 4rem 0 0 0;
  color: var(--accent-color);
}

.landing-content__coming-soon::before,
.landing-content__coming-soon::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.landing-content__coming-soon::before {
  background-color: var(--background-color);
  animation: typing 10s 1s steps(12) infinite;
}

.landing-content__coming-soon::after {
  border-left: 1px solid var(--accent-color);
  animation: typing 10s 1s steps(12) infinite,
    typing-cursor 1s steps(12) infinite;
}

@keyframes typing {
  from {
    left: 0;
  }
  5% {
    left: 0;
  }
  60% {
    left: 100%;
  }
  to {
    left: 100%;
  }
}

@keyframes typing-cursor {
  from {
    border-left: 1px solid var(--accent-color);
  }
  to {
    border-left: 1px solid transparent;
  }
}
